<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">

		<v-text-field label="Id"
									v-model="post.id"></v-text-field>

		<v-text-field label="Page"
									v-model="post.page"></v-text-field>
		<v-text-field label="Titre"
									v-model="post.titre"></v-text-field>


		<div class="post-header mb-5">


			<v-text-field label="Image"
										v-model="post.image"></v-text-field>


			<v-text-field label="Catégorie"
										v-model="post.categorie"></v-text-field>


			<v-text-field label="sous titre"
										class="post-title mt-1 mb-4"
										v-model="post.soustitre"></v-text-field>

			<ul class="post-meta mb-0">
				<li class="post-date"><i class="uil uil-calendar-alt"></i><span v-html="post.last_change" /></li>
				<li class="post-author"><i class="uil uil-user"></i><span v-html="post.auteur" /></li>

			</ul>
		</div>
		<div class="post-content ">
			<ckeditor :editor="editor"
								v-model="post.texte"
								:config="editorConfig"></ckeditor>

			<v-btn class="my-2"
						 @click="save_post()"
						 elevation=0
						 color="red"> Sauver
			</v-btn>
			<div class="row align-items-center my-10">

				<h2 class="display-4 mb-0"
						v-html="post.titre" />
			</div>

			<article class="post">
				<div class="post-slider rounded mb-6">

					<img :src="post.image"
							 class=""
							 style="width: 100%;"
							 alt="" />
				</div>


				<!-- /.basic-slider -->

				<!-- /.post-slider -->
				<div class="post-header mb-5">
					<div class="post-category text-line">
						<a href="#"
							 class="hover"
							 rel="category"
							 v-html="post.categorie" />
					</div>
					<!-- /.post-category -->
					<h2 class="post-title mt-1 mb-4"
							v-html="post.soustitre" />

					<ul class="post-meta mb-0">
						<li class="post-date"><i class="uil uil-calendar-alt"></i><span v-html="post.last_change" /></li>
						<li class="post-author"><i class="uil uil-user"></i><span v-html="post.auteur" /></li>

					</ul>
					<!-- /.post-meta -->
				</div>
				<!-- /.post-header -->
				<div class="post-content ">
					<p class="lead"
						 v-html="post.texte" />
				</div>
				<!-- /.post-content -->
			</article>
		</div>




	</div>
</section>
</template>


<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';


export default {
	name: 'edit',
	components: {

	},
	props: {},
	data: () => ({
		page: undefined,
		post: [],
		editor: ClassicEditor,
		editorData: '<p>Content of the editor.</p>',
		editorConfig: {
			// The configuration of the editor.
		}
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.page == undefined) this.page = this.$route.params.id;
		this.charge_page()


	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {

	},
	destroyed: function () {},
	computed: {},
	methods: {
		save_post() {

			let opt = this.post;
			opt["api"] = "save_post"
			opt["debug"] = true

			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) {
					this.page = this.post.page;
					this.charge_page();
				}
			})
		},
		charge_page() {
			let opt = [];
			opt["debug"] = true
			opt["api"] = "post"
			opt["page"] = this.page
			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) {
					this.post = response.data.data
					this.post.texte = this.post.texte.replaceAll('\n', '<br/>')
					this.post.texte = this.post.texte.replaceAll('', "'")
				}
			})
		}
	},
	watch: {}
}
</script>


<style  >
table {
	width: 100%
}

td {
	font-family: courier;
	font-size: 80%;
	text-align: right;
}
</style>
